<template>
  <div class="app">
    <AppHeader>
      <SidebarToggler class="d-lg-none" display="md" mobile/>
      <b-link class="navbar-brand" to="/">
        <img class="navbar-brand-full" src="img/logo.png" width="145" height="40">
        <!-- <img
          class="navbar-brand-minimized"
          src="img/brand/sygnet.svg"
          width="30"
          height="30"
          alt="CoreUI Logo"
        > -->
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg"/>
      <!-- <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/dashboard">{{ $t('message.dashboard') }}</b-nav-item>
        <b-nav-item class="px-3" to="/orders" exact>{{ $t('message.transaction') }}</b-nav-item>
        <b-nav-item class="px-3" to="/machine/machines" exact>{{ $t('message.machines') }}</b-nav-item>
        <b-nav-item class="px-3" to="/product/products" exact>{{ $t('message.products') }}</b-nav-item>
        <b-nav-item class="px-3" to="/user/users" exact>{{ $t('message.users') }}</b-nav-item>
        <b-nav-item class="px-3" to="/report/reports" exact>{{ $t('message.reports') }}</b-nav-item>
      </b-navbar-nav> -->
      <b-navbar-nav class="ml-auto">
        <!-- <b-dropdown :text="$t('message.changeLanguage')" variant="outline-primary" class="m-2" click="changeLanguage(index)">
          <b-dropdown-item>
            <p class="flag-icon flag-icon-th">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ภาษาไทย</p>
          </b-dropdown-item>
          <b-dropdown-item><span class="flag-icon flag-icon-gb" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;English</span></b-dropdown-item>
        </b-dropdown> -->
        <ChangeLangaugeDropdown />
        <DefaultHeaderDropdownAccnt/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar>
        <SidebarHeader/>
        <SidebarForm/>
        <!--            <SidebarNav :navItems="nav"></SidebarNav>-->
        <!-- <SidebarNavCustom :navItems="navTest"></SidebarNavCustom> -->
        <SidebarNavCustom></SidebarNavCustom>
        <!--
            <SidebarFooter/>
            <SidebarMinimizer/>
        -->
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside>
        aside
        <DefaultAside/>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="/">PANDATA</a>
        <span class="ml-1">&copy; 2021 ...</span>
        <span class="ml-1">version 2.9.4 build 265</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="/">THAIEASYELEC</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "../custom_modules/@coreui/vue";
import SidebarNavCustom from "../components/Sidebar";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import ChangeLangaugeDropdown from "./ChangeLangaugeDropdown";

export default {
  name: "full",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    ChangeLangaugeDropdown,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    SidebarNavCustom
  },
  data() {
    return {
      nav: this.$store.state.nav[0].name,
      navTest: this.$t('message.nav')
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    }
  },
  methods: {
    changeLanguage(index) {
      if (index === 0) {
        this.$i18n.locale = "th";
      } else if (index === 1) {
        this.$i18n.locale = "gb";
      }
    }
  }
};
</script>
